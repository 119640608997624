<template>
  <div class="position-relative">

    <div id="error-div-202012031524"
         v-if="!!error"
    >

      <b-alert
        variant="danger"
        dismissible
        :show="!!error"
        @dismissed="error=null"
      >
        <div id="error-202012031524">{{ error }}</div>
      </b-alert>

    </div>

    <b-form id="password-form-202012021507">

      <div id="oldPassword-div-202012030654"
           v-if="!token"
           role="group"
           class="mb-2"
      >

        <label for="oldPassword-202012030654" class="label" v-bind:class="[oldPassword ? 'visible': 'hidden']">
          {{ staticText.oldPasswordLabel }}
        </label>
        <password
          id="oldPassword-202012030654"
          class="mw-100"
          v-model="oldPassword"
          type="password"
          :placeholder="staticText.oldPasswordPlaceholder"
          :toggle="true"
          :showStrengthMeter="false"
          :badge="false">
        </password>

      </div>

      <div id="newPassword-div-202012021700"
           role="group"
           class="mb-2"
      >
        <label for="newPassword-202012021700" class="label" v-bind:class="[newPassword ? 'visible': 'hidden']">
          {{ staticText.newPasswordLabel }}
        </label>
        <password
          id="newPassword-202012021700"
          class="mw-100 input"
          v-model="newPassword"
          type="password"
          :placeholder="staticText.newPasswordPlaceholder"
          :toggle="true"
          @feedback="showFeedback"
          @score="checkSecure"
        ></password>
        <div id="suggestion-202012041349"
             class="mb-2 suggestion"
             v-for="item in newPasswordSuggestion"
             :key="item.index"
        >
          <p style="color:green">{{ item }}</p>
        </div>

      </div>

      <div id="newPasswordValid-div-202012021700"
           role="group"
           class="mb-2"
      >

        <label for="newPasswordValid-202012021700" class="label"
               v-bind:class="[newPasswordValid ? 'visible': 'hidden']">
          {{ staticText.newPasswordConfirmationLabel }}
        </label>
        <password
          id="newPasswordValid-202012021700"
          class="mw-100"
          v-model="newPasswordValid"
          type="password"
          :placeholder="staticText.newPasswordConfirmationLabel"
          :toggle="true"
          :showStrengthMeter="false"
          :badge="false"
          @input="error=null"
        ></password>

      </div>

      <div id="controls-div-202012030654"
           class="d-flex justify-content-end mt-4"
      >

        <b-button id="cancel-btn-202012030654"
                  class="font-size-14 px-4"
                  variant="secondary"
                  @click="handleCancel"
        >
          {{ staticText.cancelPasswordBtn }}
        </b-button>

        <b-button
          v-if="shouldShowButton"
          id="reset-btn-202012021448"
          variant="primary"
          class="font-size-14 px-4 ml-2"
          :disabled="saving || !isNewPasswordValid"
          @click="handleSubmit"
        >
          {{ staticText.resetPasswordBtn }}
        </b-button>

      </div>

    </b-form>
  </div>
</template>

<script>
import axios from "axios"
import { mapActions } from "vuex"

export default {
  name: "BasePasswordChange",
  components: { Password: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "VuePasswordStrengthMeter" */ "vue-password-strength-meter") },
  props: {
    token: {
      type: String,
      required: false,
      default: null
    },
    isForgotPasswordPage: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      axios,
      url: "/api/users/password_change",
      oldPassword: "",
      newPassword: "",
      newPasswordValid: "",
      newPasswordSuggestion: null,
      error: null,
      secure: false,
      saving: false,
      staticTextDefault: {
        oldPasswordLabel: "Current Password",
        oldPasswordPlaceholder: "Current password",
        oldPasswordFeedback: "Enter your current password.",
        newPasswordLabel: "New Password",
        newPasswordPlaceholder: "New Password",
        newPasswordConfirmationLabel: "Confirm New Password",
        cancelPasswordBtn: "Cancel",
        resetPasswordBtn: "Reset Password",
        passwordNotSecureMsg: "Your password is not secure enough!",
        passwordNotMatchMsg: "Password didn't match. Please enter matching password.",
        passwordChangedMsg: "Password changed. Please login with your new password."
      }
    }
  },
  methods: {
    ...mapActions({
      logout: "auth/logout"
    }),
    showFeedback ({ suggestions }) {
      this.newPasswordSuggestion = suggestions
    },
    handleCancel () {
      if (!this.token) {
        this.$emit("form-canceled")
        return
      }
      this.$router.push({ path: "/login" })
    },
    checkSecure (score) {
      this.error = null
      this.secure = score >= 3
    },
    checkMatch () {
      return this.newPassword === this.newPasswordValid
    },
    checkErrorExists () {
      if (!this.token && !this.oldPassword) {
        return this.staticText.oldPasswordFeedback
      }
      if (!this.secure) {
        return this.staticText.passwordNotSecureMsg
      }
      if (!this.checkMatch()) {
        return this.staticText.passwordNotMatchMsg
      }
      return null
    },
    handleSubmit () {
      this.error = this.checkErrorExists()
      if (!this.error) {
        this.changePassword()
      }
    },
    changePassword () {
      const that = this
      return new Promise((resolve, reject) => {
        that.saving = true
        const data = {
          new_password: that.newPassword,
          new_password_valid: that.newPasswordValid
        }
        let callback = () => Promise.resolve()
        if (that.token) {
          data.token = that.token
        } else {
          data.password = that.oldPassword
          callback = that.logout
        }
        that.axios.post(that.url, data).then(() => {
          that.saving = false
          this.$emit("form-completed")
          return callback().then(() => {
            that.$router.push({ path: "/login", query: { message: that.staticText.passwordChangedMsg } })
            resolve()
          })
        }).catch(error => {
          let errorMessage = error.response.data.error
          if (!errorMessage) {
            errorMessage = error.response.data.Error
          }
          that.error = errorMessage
          that.saving = false
          reject(errorMessage)
        })
      })
    }
  },
  computed: {
    shouldShowButton () {
      return this.isForgotPasswordPage || (this.oldPassword.length > 0 &&
        this.isNewPasswordValid)
    },
    isNewPasswordValid () {
      return this.newPassword.length > 0 &&
        this.newPassword === this.newPasswordValid
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}
</script>

<style scoped lang="scss">
.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.label {
  color: $slate;
  position: absolute;
  left: 15px;
  transition: .3s;
  font-size: 12px;
}

.suggestion p, .Password__badge {
  color: $orange !important;
}
</style>
