<template>
  <div class="reset-passprt-wrapper h-100">

    <!--check if token exists-->
    <div v-if="!!token"
         class="mx-auto mt-5"
    >

      <!--token is invalid-->
      <div v-if="!isTokenValid">
        <password-reset-invalid-token></password-reset-invalid-token>
      </div>

      <!--token is valid-->
      <div v-if="isTokenValid" class="card">
        <div id="message-202012021507">
          <div class="card-header p-2">
            <div class="ml-3">
              <img src="../../../public/img/icons/key-icon.svg"
                   class="mr-2"
              />
              <span id="confirmation-label-202012021507">
                {{ passwordConfirmationLabel }}
              </span>
            </div>
          </div>
        </div>
        <div class="m-4">
          <base-password-change
            :token="token"
            :is-forgot-password-page="true"
          ></base-password-change>
        </div>
      </div>
    </div>

    <!--token does not exist or page visited by accident-->
    <div v-if="!token"
         class="mx-auto col-md-5 mt-5"
    >
      <p id="accidental-label-202012021507">
        {{ accidentalMsg }}
      </p>
    </div>
  </div>
</template>

<script>
import BasePasswordChange from "./BasePasswordChange"
import PasswordResetInvalidToken from "./PasswordResetInvalidToken"
import axios from "axios"

export default {
  name: "PasswordResetPage",
  components: { BasePasswordChange, PasswordResetInvalidToken },
  data () {
    return {
      token: null,
      isTokenValid: false,
      passwordConfirmationLabel: "Change Password",
      accidentalMsg: "It looks like you stumbled upon this page by accident."
    }
  },
  mounted () {
    this.checkToken()
  },
  methods: {
    async checkToken () {
      const { token } = this.$route.params
      if (token) {
        this.token = token
        this.isTokenValid = await this.validateToken(this.token)
      }
    },
    validateToken (token) {
      return new Promise((resolve, reject) => {
        axios.post("/api/users/validate_token", { token })
          .then(response => {
            resolve(true)
          })
          .catch(error => {
            console.log(error)
            resolve(false)
          })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.reset-passprt-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $background;
  backdrop-filter: blur(20px);
}

.card {
  width: 340px;
  background-color: $white;
  box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12);
  border-radius: 20px;
}

.card-header {
  background-color: $grey;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
</style>
