<template>
  <div id="invalid-token-msg-2012171442" class="card p-4">
    <p class="text-center mb-4"
       v-html="staticText.invalidTokenMessage"
    ></p>
    <div class="text-center">
      <b-button id="go-to-login-btn-2012171451"
                class="col-md-6"
                variant="secondary"
                to="/login"
      >
        {{ staticText.goToLoginPageText }}
      </b-button>
    </div>
  </div>
</template>

<script>

export default {
  name: "PasswordResetInvalidToken",
  data () {
    return {
      staticTextDefault: {
        goToLoginPageText: "Go to Login",
        invalidTokenMessage: "You have already used this link to reset your password. To reset your password again, go to the login page and click <span><a href=\"/login\"> Forgot your password? </a></span> in oder to receive a new link via e-mail."
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
      // TODO: provide german translation for invalidTokenMessage text.
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  background-color: $white40;
  box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12);
  border-radius: 20px;
}
</style>
